import React from 'react';
import { CardExchange } from '@/components/shared/Card';

interface IListCardExchangeProps {
  listCards: {
    avatar?: string | null;
    description?: string | null;
    short_description?: string | null;
    expert_score?: any | null;
    id: any;
    link?: string | null;
    name: string;
    user_score?: any | null;
    pin?: boolean | null;
    slug: string;
  }[];
  isLoading?: boolean;
  type?: 'user' | 'expert';
}

export default function ListCardExchange({
  listCards,
  isLoading,
  type = 'user',
}: IListCardExchangeProps) {
  return (
    <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
      {(isLoading
        ? Array.from({ length: 4 }, () => undefined)
        : listCards
      )?.map((data, index) => {
        const {
          avatar,
          slug,
          name,
          short_description,
          expert_score,
          user_score,
        } = data || {};
        return (
          <div className="col-span-1" key={index}>
            <CardExchange
              src={avatar}
              slug={slug}
              type={type}
              score={type === 'user' ? user_score : expert_score}
              title={name}
              isLoading={isLoading}
              desc={short_description}
            />
          </div>
        );
      })}
    </div>
  );
}
